body {
    margin: 0px;
    height: 100%;
}

iframe {
    position: absolute;
    height: calc(100% - 3em);
    width: 100%;
    border: none
}

#settings_tray {
    padding: 0.4em 1em;
    padding-left: 1em;
    font-family: Arial;
    cursor: pointer;
    float: right;
    border-left: 1px solid #CCC;
    background-color: #0a0c0e;
    color: #fff;
}

#dispatch_queue_tray {
    position: fixed;
    bottom: 0em;
    height: 3em;
    width: 100%;
    background-color: #0a0c0e;
    color: #fff;
}

#dispatch_queue_heading {
    /* margin: 1em 1em; */
    padding: 1em 1em;
    font-family: Arial;
    cursor: pointer;
    width: 168px;
    float: left;
    border-right: 1px solid #CCC;
}

.open_settings {
    background-color: #0a0c0e;
    color: #fff;
}

/*
#dispatch_ferryname_heading {
    padding: 1em 1em;
    padding-left: 1em;
    font-family: Arial;
    cursor: pointer;
    float: left;
    border-left: 1px solid #CCC;
    border-right: 1px solid #CCC;
}
 */

/*
#dispatch_ferryname_heading:hover, #dispatch_queue_heading:hover {
    background-color: #222;
}

 */

.set_system_status {
    margin-top: 1em;
    background-color: #5ae928;
    float:right;
}

.set_system_status.turnoff {
    background-color: #999;
    float:right;
}

.dispatch_dot, .settings_dot {
    height: 1em;
    width: 1em;
    border-radius: 50%;
    display: inline-block;
    margin-left: 1em;
}

.dispatch_dot.off, .settings_dot {
    background-color: #868686;
}

.dispatch_dot.on, .settings_dot.on {
    background-color: #5ae928;
}

.assignment_history label {
    font-size: smaller;
}

.reassign-queueitem {
    display: none;
}

.buttons {
    float: right;
    font-size: 9px;
    margin-left: 3px;
}

.buttons .button {
    margin-right: 4px;
    padding: 4px;
    background-color: #0a0c0e;
}

.buttons button.open_request {
    background-color: #5fa2dd;
    color: #fff;
    font-size: 14px;
}

button.assign-queueitem, button.reassign-queueitem, button.rename_device {
    background-color: #5fa2dd;
    color: #fff;
}

button.rename_device {
    float:right;
}

.device_rename_action {
    margin-top: 1em;
}



button.btn-close {
    border: 1px solid #333;
}

button.delete-queueitem {
    background-color: #f8d7da;
    color: #721c24;
    font-size: 14px;
}

button.remove-queueitem, button.remove_request {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #721c24;
    font-size: 14px;
}

.buttons .button.assign_ferry {
    background-color: #53cf3d;
}

.buttons button {
    margin: 2px;
}

#dispatch_overlay, #settings_overlay {
    width: 300px;
    position: absolute;
    left: 0px;
    height: calc(100% - 3em);
    bottom: 3em;
    background-color: #fff;
    font-family: Arial;
    display: none;
    background-clip: padding-box;
    /* border: 1px solid #999; */
    /* border: 1px solid rgba(0,0,0,.2); */
    /* border-radius: 6px; */
    -webkit-box-shadow: 0 3px 9px rgb(0 0 0 / 50%);
    box-shadow: 0 3px 9px rgb(0 0 0 / 50%);
}

#settings_overlay {
    left: calc(100% - 300px);
}

.dispatch_overlay_container {
    width: 100%;
    /* min-height: 100%; */
    height: calc(100% - 4em);
    overflow-y: scroll;
}

.dispatch_queueitem_date, .dispatch_assignment_date {
    font-size: smaller;
    text-align: right;
}

.dispatch_queueitem_ref {
    font-weight: bold;
}

.dispatch_overlay_title_down {
    float: right;
    color: #fff;
    width: 8px;
    height: 8px;
}

.dispatch_overlay_title, .settings_overlay_title {
    background-color: #0a0c0e;
    padding: 1em;
    color: #fff;
    cursor: pointer;
}

.dispatch_overlay_queue_item, .setting_item {
    border-bottom: 1px solid #ccc;
    padding: 0.5em;
}

.dispatch_overlay_queue_item, .setting_item {
    color: #222;
    background-color: #fff;
    /* height: 120px;
    overflow-y: scroll; */
}

.setting_item {
    cursor: pointer;
}

select.device_list {
    /* width: 240px; */
    float: left;
}

select option.device_list.offline {
    background-color: #999;
}

#dispatch_popup {
    position: absolute;
    width: 200px;
    height: 100px;
    right: 20px;
    bottom: calc(100% - 200px);
    background-color: #fff;
    color: #0a0c0e;
    border: 4px solid #0a0c0e;
    font-family: Arial;
}
.dispatch_popup_title {
    background-color: #0a0c0e;
    color: #fff;
    padding: 4px;
}
.dispatch_popup_content {
    color: #0a0c0e;
    background-color: #fff;
    padding: 4px;
    font-size: 12px;
}

.modal-header {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
    color: #fff;
    background-color: #000;
}

button.close {
    color: #fff;
    opacity: 1;
}

.modal {
    text-align: center;
    padding: 0!important;
}

.modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}
.modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}

.modal-footer {
    margin-top: 18px;
}

#dispatch_alerts {

}

.alert {
    position: fixed;
    top: 1em;
    right: 1em;
    width: 240px;
    height: 100px;
    z-index: 2000;
}

.qi_assigned {
    background-color: #ddd;
}
